import { createApp } from 'vue'
import App from './App.vue'
import {createI18n} from "vue-i18n";

import 'leaflet/dist/leaflet.css';
import 'vue-map-ui/dist/normalize.css';
import 'vue-map-ui/dist/style.css';
import 'vue-map-ui/dist/theme-all.css';

import en from "../src/assets/locales/en.json";
import fr from "../src/assets/locales/fr.json";
import nl from "../src/assets/locales/du.json";
import it from "../src/assets/locales/it.json";
import de from "../src/assets/locales/de.json";
import es from "../src/assets/locales/es.json";


const messages = {
    en,
    fr,
    nl,
    it,
    de,
    es
}

let language = "en"
let languages = ["es", "nl", "fr", "it", "en", 'de']

languages.forEach(element => {
    if (navigator.language.includes(element)) {
        language = element;
    }
});

const i18n = new createI18n({
    locale: language,
    fallbackLocale: language,
    messages
})

createApp(App).use(i18n).mount('#app')
