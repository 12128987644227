<template>
  <Map/>
</template>

<script>
import Map from './pages/Map.vue'

export default {
  name: 'App',
  components: {
    Map
  }
}
</script>

<style>
@font-face {
  font-family: "Ubuntu";
  font-weight: 400;
  src: url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
}

body {
  padding: 0;
  margin: 0;
  overflow-y: hidden;
  font-weight: 400;
  font-size: 13px;
  background-color: black;
  font-family: "Ubuntu", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

@media screen and (min-width: 320px) and (max-width: 767px) and (orientation: landscape) {
  .logo {
    display: none;
  }
}


/* Grid system */
.container {
  width: 100%;
}

.row {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 5px;
}

/* content */
.row div {
  background-color: #EF5350;
  padding: 3%;
  border: 1px solid white;
  border-radius: 5px;
  text-align: right;
  color: white;
  transition: background-color 1s;
}

.row div:nth-child(2n):hover {
  background-color: #42A5F5;
}

.row div:nth-child(2n+1):hover {
  background-color: #66BB6A;
}

/* 1/12 */
.col-1 {
  width: 8.33%;
}

/* 2/12 */
.col-2 {
  width: 16.66%;
}

/* 3/12 */
.col-3 {
  width: 25%;
}

/* 4/12 */
.col-4 {
  width: 33.33%
}

/* 5/12 */
.col-5 {
  width: 41.66%;
}

/* 6/12 */
.col-6 {
  width: 50%;
}

/* 7/12 */
.col-7 {
  width: 58.33%;
}

/* 8/12 */
.col-8 {
  width: 66.66%;
}

/* 9/12 */
.col-9 {
  width: 75%;
}

/* 10/12 */
.col-10 {
  width: 83.33%;
}

/* 11/12 */
.col-11 {
  width: 91.66%;
}

/* 12/12 */
.col-12 {
  width: 100%;
}

/* viewport <= 630px */
@media screen and (max-width: 630px) {
  .row div {
    padding: 1.5%;  
  }    
} 

/* viewport <= 500px */
</style>
