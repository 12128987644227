<template>
  <div>
    <img v-if="!displayInfoPanel" src="../assets/logo.png" class="logo"/>
    <input type="text" name="search" :placeholder="$t('main.where_are_you_going')" v-model="search" @keyup="searchCountry" class="peaceplace-search-input" autocomplete="on" :style="!displayInfoPanel ? 'display: block': 'display:none'"/>
    <ul v-if="!displayInfoPanel && results.length > 0" class="peaceplace-search-input-result">
      <li v-for="result in results" :key="result.id" @click="searchCurrentCity(result.country, result.name)" >
        <span v-if="!result.iso2"><b>{{ result.name }}</b>, {{ result.country }} <img style="vertical-align: middle;" v-if="result.is_capital" src="../assets/capital.png" width="15px"></span>
        <span v-if="result.iso2"><b>{{ result.name }}</b>, {{ result.iso2 }} <img style="vertical-align: middle;" src="../assets/search.png" width="15px"></span>
      </li>
    </ul>
    <VMap style="height: 100vh; overflow-y: hidden;" :center="center" :zoom="2.5" :min-zoom="1"  :max-zoom="5" @view-changed="onViewChanged" @click="onClick">
      <VMapGoogleTileLayer :detectRetina="true" />
      <VMapMarker  v-for="countrie in countries" v-bind:key="countrie.name" :latlng="countrie.coordinates" @click="onClickMarker(countrie)">
        <VMapPinIcon
        >
          <img v-if="countrie.black_people_welcomed == 4 " src="../assets/peaceplace.png">
          <img v-if="countrie.black_people_welcomed == 3 " src="../assets/coolplace.png">
          <img v-if="countrie.black_people_welcomed == 2" src="../assets/vigilantplace.png">
          <img v-if="countrie.black_people_welcomed == 1 " src="../assets/dangerousplace.png">
        </VMapPinIcon>
      </VMapMarker>
    </VMap>

    <div v-if="displayInfoPanel && currentCountrie" class="info-panel">
      <div class="wrap" style="padding-top: 40%;" v-if="!currentCountrie.comment"> 
        <div class="loader-logo-container">
          <img src="../assets/logo.png" class="loader"/>
        </div>
        <div class="loader-text">Searching information for <b>{{currentCountrie.name}}</b>...</div>
      </div>
      <div class="wrap" v-if="currentCountrie.comment" >
        
        <div class="header" :style="!currentCountrie.image ? { backgroundImage: `url(${genericImage})` } : { backgroundImage: `url(${currentCountrie.image})` }" >
          <div class="bg">
          </div>
          <div class="filter">
            <img src="../assets/back.png" @click="closeInfoPanel" style="cursor: pointer; margin: 20px 5px; color: white; width: 40px;" aria-hidden="true"/>
            <div @click="shareButton" style="position: absolute;cursor: pointer;  text-align:center; font-size: 30px; color: white;  top: 20px; right: 10px;display: flex; flex-direction: column;">
              <img src="../assets/share.png"  style="width: 40px;" aria-hidden="true"/>
            </div>
          </div>
          <div class="title">
            <div class="fromPlace">
              {{ currentCountrie.name.toUpperCase() }}
            </div>
          </div>
        </div>

        <div class="content" style="overflow-y: hidden;">
          <section>
            <div class="form">

              <div class="panel" v-if="!this.currentCountrie.is_city" style="width: 100%; height: 25vh; padding-top: 0.3vh!important">
                <div class="panel-head">
                  <div style="width: 100%; height: 25vh">
                    <carousel :items-to-show="isMobile ? 1.5: 3"  snapAlign="start" v-if="currentCountrie.is_city == false" style="height: 25vh">
                      <template #addons v-if="!isMobile">
                        <navigation  />
                      </template>
                      <slide v-for="city in currentCountrie.touristic_cities" style="height: 25vh; background-image: url('https://dynamic-media-cdn.tripadvisor.com/media/photo-o/17/15/6d/d6/paris.jpg?w=1400&h=1400&s=1'); border-radius: 5px; background-color: black; margin-left: 5px;" :style="{ backgroundImage: `url(${MEDIA_URL+city.image.split('/').pop()})`, backgroundSize: '100% 100%'}" :key="city" @click="setCurrentCity(city)">
                        <h2 style="width: 100%; border-radius: 5px; background-image: linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,0.7)); height: 100%; padding-top: 15%; color: white;"> <span style="position: absolute; bottom: 15%; left: 20px; font-size: x-large;">{{ city.name}}</span></h2>
                      </slide>
                    </carousel>
                  </div>			
                </div>
              </div>

              <div class="panel" style="width: 100%; margin-top: 3vh;">
                <div class="panel-head">

                  <div class="panel-item">
                    <i class="fa fa-handshake-o" aria-hidden="true"></i>
                  </div>

                  <div class="panel-item panel-content">
                    <h4>{{ $t("main.chef_of_state") }}</h4>
                    <span>{{ currentCountrie.manager_of_the_country }}</span>
                  </div>
                  <div class="panel-item panel-content">
                    <h4>{{$t("main.country_dominant_party")}}</h4>
                      <span v-if="currentCountrie.dominant_formation == 6"> {{$t("main.royalti")}}  </span>
                      <span v-if="currentCountrie.dominant_formation == 5 "> {{$t("main.far_left")}} </span>
                      <span v-if="currentCountrie.dominant_formation == 4"> {{$t("main.left")}}  </span>
                      <span v-if="currentCountrie.dominant_formation == 3"> {{$t("main.center")}}  </span>
                      <span v-if="currentCountrie.dominant_formation == 2"> {{$t("main.right")}}   </span>
                      <span v-if="currentCountrie.dominant_formation == 1"> {{$t("main.far_right")}}   </span>
                  </div>
                </div>
                <div class="section-separation"></div>
              </div>

              <div class="panel">
                <div class="panel-head">
                  <div class="panel-item">
                    <i class="fa fa-search" aria-hidden="true"></i>
                  </div>

                  <div class="panel-item panel-content">
                    <h4>{{ $t("main.black_people_welcomed") }}</h4>
                    <span class="text-content">
                      <img v-if="currentCountrie.black_people_welcomed == 4" width="30"  style="vertical-align: middle;" src="../assets/peaceplace.png">
                      <img v-if="currentCountrie.black_people_welcomed == 3 " width="30"  style="vertical-align: middle;" src="../assets/coolplace.png">
                      <img v-if="currentCountrie.black_people_welcomed == 2" width="30"  style="vertical-align: middle;" src="../assets/vigilantplace.png">
                      <img v-if="currentCountrie.black_people_welcomed == 1" width="30"  style="vertical-align: middle;" src="../assets/dangerousplace.png">
                      <span v-if="currentCountrie.black_people_welcomed == 4"> Peace place  </span>
                      <span v-if="currentCountrie.black_people_welcomed == 3 "> Ok (Might face tensions)  </span>
                      <span v-if="currentCountrie.black_people_welcomed == 2"> Stay vigilant  </span>
                      <span v-if="currentCountrie.black_people_welcomed == 1"> Might be dangerous  </span>
                    </span>
                  </div>
                  <div class="panel-item panel-content">
                    <h4>{{ $t("main.touristic_notation") }}</h4>
                    <span v-if="currentCountrie.touristic_notation <= 2"><i v-for="index in currentCountrie.touristic_notation" :key="index" class="fa fa-circle star" style="background-color: rgba(255, 0, 0, 0.7); color: white;"></i></span>
                    <span v-if="currentCountrie.touristic_notation == 3"><i v-for="index in currentCountrie.touristic_notation" :key="index" class="fa fa-circle star" style="background-color: #0f3f88; color: white"></i></span>
                    <span v-if="currentCountrie.touristic_notation == 4"><i v-for="index in currentCountrie.touristic_notation" :key="index" class="fa fa-circle star" style="background-color: #0f3f88; color: white"></i></span>
                    <span v-if="currentCountrie.touristic_notation > 4"><i v-for="index in currentCountrie.touristic_notation" :key="index" class="fa fa-circle star" style="background-color: #0f3f88; color: hsla(354, 86%, 59%, 1)"></i></span>
                    <i v-for="negIndex in negativeStars" :key="negIndex" class="fa fa-circle star-out"></i>
                  </div>

                </div>
                <div class="section-separation"></div>

              </div>

              <div>
                <div class="panel-head">
                  <div>
                    <h3>{{ $t("main.comment") }}</h3>
                    <span class="text-content" style="text-align: justify;">{{currentCountrie.comment}}</span>
                  </div>			
                </div>
              </div>
              
              <div  v-if="this.currentCountrie.is_city && this.currentCountrie.places_to_visit" style="margin-top: 10px;" class="panel-head">
                  <div>
                    <h3>{{ $t("main.activities") }}</h3>
                    <carousel :items-to-show="isMobile ? 1.5: 3"  snapAlign="start" v-if="currentCountrie.is_city == true" style="height: 27vh; padding-right: 3px;">
                      <template #addons v-if="!isMobile">
                        <navigation  />
                      </template>
                      <slide v-for="place in currentCountrie.places_to_visit" style="background-size: 100%;height: 25vh; border-radius: 5px; background-color: black; margin-left: 5px; background-size: 100% 100%;" :style="isMostVisitedCountry(this.currentCountrie.country_name) ? {backgroundImage: `url(${place.image})`} : {backgroundImage: `url(${currentCountrie.image})`}" :key="place">
                        <span class="check-pills" v-if="place.is_free">Free</span>
                        <span class="info-pills" v-if="!place.is_free"> <i style="color: rgba(255, 255, 255, 0.4);" class="fa fa-info-circle"></i> Book a ticket</span>
                        <div style="width: 100%; border-radius: 5px; background-image: linear-gradient(to bottom, rgba(0,0,0,0.5), rgba(0,0,0,0.8)); height: 100%; padding-top: 15%; color: white;" > 
                          <h2>{{ place.name }}</h2>
                          <h4 style="margin-top: -5px;">{{$t("main.visiting_time")}}</h4>
                          {{place.visiting_time.split('to')[0]}} {{$t("main.to")}} {{place.visiting_time.split('to')[1]}}
                        </div>
                      </slide>
                    </carousel>
                </div>
              </div>


            </div>
          </section>
        </div>		
      
      </div>
      
    </div>
  </div>
</template>

<script>
import { VMap, VMapGoogleTileLayer, VMapPinIcon, VMapMarker} from 'vue-map-ui';
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Navigation } from 'vue3-carousel'

const countries = require('alpha2-countries')

export default {
  name: 'MapView',
  components: {
    VMap,
    VMapMarker,
    VMapGoogleTileLayer,
    VMapPinIcon, 
    Carousel,
    Slide,
    Navigation
  },
  props: {
    msg: String
  },
  data() {
    return {
      center: [39,21.21667],
      genericImage: "https://images.pexels.com/photos/5232394/pexels-photo-5232394.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      countries: null,
      currentCountrie: null,
      currentCountrieImages: [],
      backCountrie: null,
      displayInfoPanel: false,
      isLoading: false,
      search: "",
      results: [],
      mostVisitedCountry: [
        "France", "Spain", "United States", "China", "Italy", 
        "Turkey", "Mexico", "Thailand", "Germany", "England", 
        "Japan", "Austria", "Greece", "Russia", "Saudi Arabia", 
        "Canada", "Portugal", "Poland", "Netherlands", "South Korea"
      ],
      options: {
        currentPage: 0
      },
      visitsCarouselEnabled: false,
      API_URL: process.env.VUE_APP_API_URL,
      MEDIA_URL: process.env.VUE_APP_API_URL + "/media/",
      FRONT_URL: process.env.VUE_APP_FRONT_URL
    };
  },
  computed : {
    negativeStars() {
      return 5 - this.currentCountrie.touristic_notation
    },
    isMobile () {
      return window.screen.width < 600
    }
  },
  methods: {
    onClickMarker (countrie) {
      countrie["is_city"] = false
      this.currentCountrie = countrie;
      this.displayInfoPanel = true
      this.getCountryInfo(countrie["name"]);
    },
    setCurrentCity(city) {
      this.backCountrie = this.currentCountrie;
      let manager_of_the_country = this.currentCountrie.manager_of_the_country
      let dominant_formation = this.currentCountrie.dominant_formation
      let country_name = this.currentCountrie.name
      this.displayInfoPanel = true
      this.search = ""
      this.results = true
      this.currentCountrie = city
      this.currentCountrie["manager_of_the_country"] = manager_of_the_country
      this.currentCountrie["dominant_formation"] = dominant_formation
      this.currentCountrie["is_city"] = true
      this.getCityInfo(country_name, city["name"]);
    },
    isMostVisitedCountry() {
      console.log(this.currentCountrie.country_name)
      console.log(this.mostVisitedCountry.indexOf(this.currentCountrie.country_name) != -1)
      return this.mostVisitedCountry.indexOf(this.currentCountrie.country_name) != -1
    },
    searchCurrentCity(country, city) {
      this.currentCountrie = {}
      this.displayInfoPanel = true
      this.search = ""
      this.results = true
      if (!country) {
        country = city
        this.currentCountrie = this.findCountry(country);
        this.getCountryInfo(country).then(() => {
          this.currentCountrie["is_city"] = false
        })
        .catch((err) => {
          console.log(err)
          // window.location.href = this.VUE_APP_FRONT_URL 
        })
      } else {
        country = countries.resolveName(country)
        this.getCityInfo(country, city).then((data) => {
          let city_country = this.findCountry(data["country_name"]);
          this.currentCountrie["manager_of_the_country"] = city_country["manager_of_the_country"]
          this.currentCountrie["dominant_formation"] = city_country["dominant_formation"]
          this.currentCountrie["is_city"] = true
        })
        .catch((err) => {
          console.log(err)
          // window.location.href = this.VUE_APP_FRONT_URL 
        })
      }
    },
    async shareButton() {
      let url = null
      let shareData = {
        title: "I found a cool place ✈️☀️!",
        text: null,
        url: null,
      };
      if (this.currentCountrie["is_city"]) {
        url = this.FRONT_URL + `?country=${this.currentCountrie["country_name"]}&city=${this.currentCountrie["name"]}`
        shareData["url"] = url
      } else {
        url = this.FRONT_URL + `?country=${this.currentCountrie["name"]}`
      }
      try {
        await navigator.share(shareData);
      } catch (err) {
        navigator.clipboard.writeText(url);
        alert('Copied')
      }
    },
    loadCountries() {
      fetch(`${this.API_URL}/map/countries`)
      .then((response) => response.json())
      .then((json) => this.countries = json)
      .then(() => {
        this.loadUrlData();
      })
    },
    onViewChanged(e) {
      this.center = e.center;
      this.zoom = e.zoom;
      this.bounds = e.bounds;
    },
    async searchCountry () {
      let cityName = this.search
      const url = `${this.API_URL}/map/search?name=${cityName}`;

      try {
          const response = await fetch(url)
          if (!response.ok) {
              throw new Error(`Error fetching city info: ${response.statusText}`);
          }
          const data = await response.json();
          this.results = data.response
      } catch (error) {
          console.error(error.message);
      }
    },
    findCountry (country) {
      return this.countries.find(obj => obj["name"] === country);
    },
    async onClick(e) {
      let lat = e.latlng.lat
      let long = e.latlng.lng
      const url = `${this.API_URL}/map/position?lat=${lat}&long=${long}`;

      const response = await fetch(url);
      const data = await response.json();
      let result = data.response.results[0].components.country;
      let country = this.findCountry(result);
      this.results = []
      if (country) {
        country["is_city"] = false
        this.currentCountrie = country
        this.displayInfoPanel = true
      }
    },
    async getCountryInfo (country) {
      this.displayInfoPanel = true
      this.currentCountrie["comment"] = null
      const url = `${this.API_URL}/map/country?country=${country}&lang=${this.$i18n.locale}`;
      const response = await fetch(url);
      const data = await response.json();
      this.currentCountrie["comment"] = data["response"]["comment"]
    },
    async getCityInfo (country, city) {
      this.currentCountrie["comment"] = null
      const url = `${this.API_URL}/map/city?country=${country}&city=${city}&lang=${this.$i18n.locale}`;
      const response = await fetch(url);
      const data = await response.json();
      this.currentCountrie["name"] = city
      this.currentCountrie["touristic_notation"] = data["response"]["touristic_notation"]
      this.currentCountrie["black_people_welcomed"] = data["response"]["black_people_welcomed"]
      this.currentCountrie["country_name"] = data["response"]["country_name"]
      this.currentCountrie["comment"] = data["response"]["comment"]
      this.currentCountrie["image"] = data["response"]["image"]
      this.currentCountrie["places_to_visit"] = data["response"]["places_to_visit"]
      return this.currentCountrie
    },
    closeInfoPanel () {
      if (!this.backCountrie) {
        this.displayInfoPanel = false;
        this.currentCountrie = null
      } else {
        this.currentCountrie = this.backCountrie;
        this.backCountrie = null;
      }
    },
    loadUrlData() {
      let urlParams = new URLSearchParams(window.location.search);
      let country = urlParams.get('country')
      let city = urlParams.get('city')
      country = this.findCountry(country);

      if(country && !city) {
        if (country) {
          country["is_city"] = false
          this.currentCountrie = country
          this.displayInfoPanel = true
        }
      } else if (country && city) {
        this.searchCurrentCity(country, city)
      }
    }
  },
  created () {
    this.loadCountries();
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
   .star {
    background-color: #86d11d;
    color: #fff;
    padding: 5px;
    margin-right: 5px;
    border-radius: 5px;
    font-size: 13px;
    margin-top: 5px;
    margin-bottom: 5px;
   }

   .star-out {
    background-color: rgb(211, 211, 211, 0.4);
    color: rgb(211, 211, 211, 0.4);
    padding: 5px;
    margin-right: 5px;
    border-radius: 5px;
    font-size: 12px;
    margin-top: 5px;
    margin-bottom: 5px;
   }

   .info-panel {
    z-index: 2000;
    top:0;
    right: 0px;
    width: 40%;
    height: 100%;
    position: absolute;
    background-color: rgb(255, 255, 255);
    padding: 0px;
    overflow-y: scroll;
   }

   .panel-info-marker {
    width: 70px;
    margin-left: 20px;
   }

   .close-info-panel {
    cursor: pointer;
    left: 20px;
    top: 15px; 
    position: absolute;
   }


   .peaceplace-search-input-result
   {
      position: absolute;
      left: calc(52% - 15%);
      margin-top: calc(5% + 50px);
      outline: none;
      border: none;
      font-family: 'Belanosima', sans-serif;
      box-shadow: 0px 0px 0px grey;
      width: 33.5%;
      border-radius: 0px 0 25px 25px;
      background-color: white;
      list-style-type: none;
      padding-left: 0px;
      z-index: 1000;
      text-align: left;
      padding-bottom: 20px;
   }

   .peaceplace-search-input-result > li {
    cursor: pointer;
    padding: 10px 0 10px 10px;
   }

   .peaceplace-search-input-result > li:hover {
    background-color: lightgrey
   }
   
   .logo {
      margin-top: 10px;
      right:0;
      height: 60px;
      width: 60px;
      padding: 5px;
      z-index: 1000;
      position: absolute;
      border-radius: 50%;
      left: calc(50% - 30px);
      background: linear-gradient(135deg, hsla(354, 86%, 59%, 1) 15%, hsla(224, 100%, 20%, 1) 40%);
      background: -moz-linear-gradient(135deg, hsla(354, 86%, 59%, 1) 15%, hsla(224, 100%, 20%, 1) 40%);
      background: -webkit-linear-gradient(135deg, hsla(354, 86%, 59%, 1) 15%, hsla(224, 100%, 20%, 1) 40%);

   }

    @keyframes scale {
      0%   { transform: scale(0.3,0.3); }
      100% { transform: scale(0.8,0.8); }
    }

   .loader {
      width: 25%;
      z-index: 1000;
      border-radius: 50%;
      padding: 20px;
      box-shadow: 0px 5px 10px grey;
      animation-name: scale;
      animation-duration: 1.5s; 
      animation-fill-mode: forwards;
      animation-iteration-count: infinite;
      background: linear-gradient(135deg, hsla(354, 86%, 59%, 1) 15%, hsla(224, 100%, 20%, 1) 40%);
      background: -moz-linear-gradient(135deg, hsla(354, 86%, 59%, 1) 15%, hsla(224, 100%, 20%, 1) 40%);
      background: -webkit-linear-gradient(135deg, hsla(354, 86%, 59%, 1) 15%, hsla(224, 100%, 20%, 1) 40%);
   }

   .loader-logo-container {
    text-align: center;
   }
   .loader-text {
      width: 100%;
      text-align: center;
      z-index: 1000;
   }

   .peaceplace-search-input
   {
      background: url("../assets/search.png") no-repeat scroll 5% 50% color(srgb 255 255 255);
      background-size: 20px;
      position: absolute;
      left: calc(50% - 15%);
      margin-top: 5%;
      outline: none;
      border: none;
      font-family: 'Belanosima', sans-serif;
      box-shadow: 0px 5px 10px grey;
      width: 30%;
      border-radius: 25px;
      z-index: 1000;
      padding: 20px;
      padding-left: calc(5% + 25px);
   }

   input[type="submit"]
   {
       display: none;
   }
   @media only screen and (max-width: 600px) 
   {  
      h4 {
        font-size: 11px;
      }
      .peaceplace-search-input-result {
        margin-top: calc(20% + 55px) !important;
        width: 76%;
        left: 12%;
      }
      .peaceplace-search-input
       {
          width: 76%;
          margin-top: 20%;
          left: 5%;
       }
       .info-panel {
          width: 100%;
          border-radius: 0px 0px 0px 0px;
        }
        .wrap {
          border-radius: 0px 0px 0px 0px;
        }
        .leaflet-container .leaflet-marker-pane img {
         width: 4vh !important;
        }
        .logo {
          margin-top: 5px;
          height: 55px;
          width: 55px;
          box-shadow: 0px 5px 10px grey;
          left: calc(50% - 27.5px);
        }
        .star {
          font-size: 14px;
        }
        .star-out {
          font-size: 14px;
        }
        .loader {
          width: 50%;
        }
   }
   .leaflet-container .leaflet-marker-pane img {
    width: 5vh;
   }
   
/* carroussel */

.wrap {
    background-color: #fff;
    position: relative;
    display: block;
    border-radius: 0px 0 0 0;
}

.header {
  width: 100%;
  height: 20vh;
  position: relative;
  background-image: linear-gradient(45deg, #445878 0%, #92CDCF 100%);
  background-size: 100% 250% !important;
}

.header .bg,
.header .filter {
  position: absolute; 
  width: 100%; 
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.header .bg {
  background-size: 100% 100%;
  opacity: 0;
  transition: all 0.8s ease;
}

.header .title {
  display: none;
  align-items: center;
  justify-content: space-around;
  font-size: 2em;
  color: #fff;
  height: 100%;
}

.title > div { 
  z-index: 10; 
}

.content {
  height: 100%;
  background-color: #fff;
  position: relative;
  overflow-y: scroll;
  overflow-x: hidden;
}

.content > section {
  position: relative;
  width: 300%;
}

.header .title { display: flex }
.header .bg { opacity: 1 }
.header { height: 20vh }

.form {
  float: left;
  width: 33.33333%;
  height: 100%;
  padding: 0;
  margin: 0;
  position: relative;
}

.panel {
  position: relative;
  top: 0;
  display: flex;
  align-items: center;
  padding: 5px;
  background: #fff; /*var(--app-bg-color);*/
  transition: all .4s ease;
}

.section-separation {
  margin-top: 4%;
  margin-left: 5%;
  width: 90%;
  border-top: solid 0.2px #bbb !important;
}

.panel-content { width: 40%;}

.panel-head > div,
.panel > .panel-item {
  margin-left: 15px;
}

 h4 { 
  margin: 5px 0;
  font-weight: 400;
  font-weight: bold;
}

.panel .panel-head { 
  display: flex; 
  align-items: center;
  margin-top: 5px;
  cursor: pointer;
}

.panel { display: block; }

.panel .close {
  display: none;
  position: absolute;
  right: 15px;
  top: 15px;
  font-size: 20px;
}

.check-pills {
  position: absolute;
  top: 75%;
  /* right: 10%; */
  margin-bottom: 10px;
  border-radius: 15px;
  color: rgb(91, 202, 91);
  font-weight: bold;
  padding: 10px;
  background-color: rgb(91, 202, 91, 0.1);
  text-align: center;
}

.info-pills {
  position: absolute;
  align-self: center;
  top: 75%;
  /* right: 10%; */
  margin-bottom: 10px;
  border-radius: 15px;
  color: white;
  font-weight: bold;
  padding: 10px;
  text-align: center;
  color: rgb(238, 211, 61);
  background-color: rgb(238, 211, 61, 0.1);
}

</style>
